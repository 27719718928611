/* eslint-disable @typescript-eslint/no-unused-vars */
import { Close } from "@mui/icons-material";
import { Box, Dialog, DialogContent, DialogTitle, IconButton, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import Loader from "src/Components/Common/Loader";
import useFetch from "src/Components/Common/useFetch";
import $ from "src/Components/Recon360/PartnerCommunication/MsmePartnerPortal/PartnerPortal.module.scss";
import { API_ENDPOINTS } from "src/Utils/ApiConstants/ApiUrlConstants";
import { BcBetaContext } from "../../../BalanceConfirmationBeta/BalanceConfirmationBeta";
import { CommonDialogProps } from "../../CommonDialogs";
import { AutoReminder } from "../../PartnerCommsInterfaces";
import SingleAutoReminder from "./SingleAutoReminder";

interface ActiveAutoReminderProps extends CommonDialogProps {}

const ActiveAutoReminder = ({ open, setOpen }: ActiveAutoReminderProps) => {
  const { companyId, branchCode } = useContext(BcBetaContext);

  const [loading, setLoading] = useState(false);
  const [autoReminders, setAutoReminders] = useState<AutoReminder[]>([]);

  const handleClose = () => {
    setOpen(false);
  };

  const ListAllActiveAutoReminders = () => {
    return useFetch<{ details: AutoReminder[] }>(API_ENDPOINTS.LIST_ALL_ACTIVE_AUTO_REMINDERS.url, "GET", {
      failureMessage: API_ENDPOINTS.LIST_ALL_ACTIVE_AUTO_REMINDERS.failureMessage,
      config: {
        params: {
          companyId: companyId,
          branchCode: branchCode,
        },
      },
      thenCallBack: (_res) => {
        if (_res.data?.details) setAutoReminders(_res.data?.details || []);
      },
    });
  };

  const getActiveAutoReminders = async () => {
    setLoading(true);
    await ListAllActiveAutoReminders();
    setLoading(false);
  };

  useEffect(() => {
    if (open) getActiveAutoReminders();
  }, [open]);

  return (
    <>
      <Dialog open={open} onClose={() => handleClose()} classes={{ paper: $.BR_fix }} maxWidth="md" fullWidth>
        <Box className="dialog_header space_between" alignItems="center" pr={1}>
          <DialogTitle component={"div"} width={"95%"}>
            Active Auto-reminders
          </DialogTitle>
          <IconButton
            onClick={() => {
              handleClose();
            }}
          >
            <Close />
          </IconButton>
        </Box>
        <DialogContent className="d_flex" sx={{ flexFlow: "column", gap: 2, minHeight: 240 }}>
          {loading ? (
            <Loader />
          ) : autoReminders.length < 1 ? (
            <Typography className="center_align_ver_horiz" my={"auto"} variant="overline" fontStyle={"italic"}>
              No Active Auto-Reminders
            </Typography>
          ) : (
            autoReminders?.map((reminder) => (
              <SingleAutoReminder
                key={reminder.id}
                autoReminder={reminder}
                listAction={async () => {
                  await ListAllActiveAutoReminders();
                }}
              />
            ))
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ActiveAutoReminder;
