import { Add, Close } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  Stack,
  TextField,
} from "@mui/material";
import { useContext, useState } from "react";
import React from "react";
import useFetch from "src/Components/Common/useFetch";
import $ from "src/Components/Recon360/PartnerCommunication/MsmePartnerPortal/PartnerPortal.module.scss";
import { EmailInput } from "src/Components/Recon360/ReconMIS/ClientReporting/AutoCompletes";
import { UseCaseType } from "src/entity/recon-entity/ReconInterfaces";
import { API_ENDPOINTS } from "src/Utils/ApiConstants/ApiUrlConstants";
import { BcBetaContext } from "../../../BalanceConfirmationBeta/BalanceConfirmationBeta";
import { PartnerCommsContext } from "../../../Context/PartnerCommunicationContext";
import { NdAsyncButton } from "../../../MsmePartnerPortal/CommonComponents";
import { CommonDialogProps } from "../../CommonDialogs";
import CreateEmailTemplate from "../../CreateEmailTemplate";

interface SetupNewReminderProps extends CommonDialogProps {
  afterClose: () => void;
}

const SetupNewReminder = ({ open, setOpen, afterClose }: SetupNewReminderProps) => {
  const [reminderName, setReminderName] = useState<string>("");
  const [templateName, setTemplateName] = useState<string>("");
  const [templateError, setTemplateError] = useState<boolean>(false);
  const [emailTo, setEmailTo] = useState<string[]>([]);
  const [emailCc, setEmailCc] = useState<string[]>([]);
  const [reminderDays, setReminderDays] = useState<number | string>("");
  const [reminderTimes, setReminderTimes] = useState<number | string>("");

  const { emailTemplates } = useContext(PartnerCommsContext);
  const { companyId, branchCode, listUserEmailTemplates } = useContext(BcBetaContext);

  const [openEmailTemplate, setOpenEmailTemplate] = useState(false);

  const emailTemplate = emailTemplates.find((item) => item.templateName === templateName);

  const handleClose = () => {
    setOpen(false);

    // reset states
    setReminderName("");
    setTemplateName("");
    setTemplateError(false);
    setEmailTo([]);
    setEmailCc([]);
    setReminderDays("");
    setReminderTimes("");

    afterClose();
  };

  const CreateNewAutoReminder = async () => {
    return useFetch<{ response: string; message: string }>(API_ENDPOINTS.CREATE_NEW_AUTO_REMINDER.url, "POST", {
      failureMessage: API_ENDPOINTS.CREATE_NEW_AUTO_REMINDER.failureMessage,
      showSuccessToast: true,
      data: {
        companyId: companyId,
        branchCode: branchCode,
        templateName: reminderName,
        reminderDays: reminderDays,
        reminderCount: reminderTimes,
        to: emailTo,
        cc: emailCc,
        emailTemplateId: emailTemplate?.templateId,
      },
      thenCallBack: (res) => {
        console.log(res);
      },
    });
  };

  return (
    <>
      <Dialog open={open} onClose={() => handleClose()} classes={{ paper: $.BR_fix }} maxWidth="md" fullWidth>
        <Box className="dialog_header space_between" alignItems="center" pr={1}>
          <DialogTitle component={"div"} width={"95%"}>
            Create Auto-reminder
          </DialogTitle>
          <IconButton
            onClick={() => {
              handleClose();
            }}
          >
            <Close />
          </IconButton>
        </Box>
        <DialogContent>
          <Stack width="100%" gap={2} sx={{ ".MuiInputBase-root": { borderRadius: 1 } }}>
            <TextField
              fullWidth
              value={reminderName}
              onChange={(e) => {
                setReminderName(e.target.value);
              }}
              variant="outlined"
              label="Enter template name"
            />
            <Stack gap={2} alignItems="flex-start">
              <Box className="d_flex" gap={2} width={"100%"} alignItems={"flex-start"}>
                <TextField
                  select
                  label="Select Email Template"
                  value={templateName}
                  variant="outlined"
                  fullWidth
                  error={templateError}
                  helperText={templateError ? "Please Select Template" : ""}
                  onBlur={() => {
                    if (!templateName) setTemplateError(true);
                    else setTemplateError(false);
                  }}
                  onChange={async (e) => {
                    const filteredSelectedTemplate = emailTemplates.filter(
                      (item) => item.templateName === e.target.value
                    );

                    setTemplateName(filteredSelectedTemplate[0]?.templateName || "");
                  }}
                >
                  {emailTemplates.length > 0 &&
                    emailTemplates.map((item, itemInd: number) => (
                      <MenuItem className="d_flex" key={itemInd} value={item.templateName}>
                        {item.templateName}
                      </MenuItem>
                    ))}
                  <MenuItem value="">Select Template</MenuItem>
                </TextField>
                <Button
                  variant="text"
                  startIcon={<Add />}
                  sx={{ minWidth: 120, mt: 1.5 }}
                  disabled={!templateName}
                  onClick={() => {
                    setOpenEmailTemplate(true);
                    setTemplateName(templateName);
                  }}
                >
                  View/edit
                </Button>
              </Box>
              <Button
                className={$.BR_fix}
                variant="outlined"
                startIcon={<Add />}
                onClick={() => {
                  setOpenEmailTemplate(true);
                  setTemplateName("");
                }}
              >
                New Email Template
              </Button>
            </Stack>
            <Stack direction="row" gap={2}>
              <TextField
                value={reminderDays}
                onChange={(e) => {
                  e.target.value = e.target.value.replace(/\D/g, "");
                  setReminderDays(e.target.value ? Number(e.target.value) : e.target.value);
                }}
                fullWidth
                variant="outlined"
                label="After how many days the reminder should go?"
              />
              <TextField
                value={reminderTimes}
                onChange={(e) => {
                  e.target.value = e.target.value.replace(/\D/g, "");
                  setReminderTimes(e.target.value ? Number(e.target.value) : e.target.value);
                }}
                fullWidth
                variant="outlined"
                label="How many times the reminders should go?"
                helperText="Min 1 and Max 7"
              />
            </Stack>
            <EmailInput
              emails={emailTo}
              setEmails={setEmailTo}
              label="To"
              size="medium"
              fullWidth
              sx={{ width: "100%" }}
              textFieldProps={{
                variant: "outlined",
                helperText: "The emails in “To” will be added along with the existing email ids in the communication",
              }}
            />

            <EmailInput
              emails={emailCc}
              setEmails={setEmailCc}
              label="CC"
              size="medium"
              fullWidth
              sx={{ width: "100%" }}
              textFieldProps={{
                variant: "outlined",
                helperText: "The emails in “CC” will be added along with the existing email ids in the communication",
              }}
            />
          </Stack>
        </DialogContent>
        <DialogActions className="dialog_footer" sx={{ p: 1.5 }}>
          <NdAsyncButton
            // className={$.BR_fix}
            variant="contained"
            onClick={async () => {
              await CreateNewAutoReminder();
              handleClose();
            }}
          >
            Create Auto-Reminder
          </NdAsyncButton>
        </DialogActions>
      </Dialog>
      <CreateEmailTemplate
        key={templateName}
        open={openEmailTemplate}
        setOpen={setOpenEmailTemplate}
        listUserEmailTemplates={listUserEmailTemplates}
        templateType={UseCaseType.balanceConfirmationBeta}
        selectedTemplateName={templateName}
      />
    </>
  );
};

export default SetupNewReminder;
